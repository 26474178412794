import * as React from 'react'
import PropTypes from 'prop-types'
import joinClasses from '/src/functions/joinClasses'
import parse from 'html-react-parser'
//import { Link } from 'gatsby'

const ButtonLink = ({ data, variant, size, icon, className, style, utm }) => {
    const classes = [
        'btn',
        ...(variant ? [`btn-${variant}`] : ['btn-primary']),
        ...(size ? [`btn-${size}`] : []),
        ...(icon ? [`btn-${icon}`] : []),
        ...(className ? [className] : [])
    ]

    const concatenatedClasses = joinClasses(classes)

    let link

    if (data.url) {
        if (data.url.indexOf('#') !== -1) {
            // If this button is an anchor, don't add a UTM
            link = data.url
        } else {
            link = `${data.url}${utm}`
        }
    } else if (data.link) {
        link = `${data.link}${utm}`
    }

    if (!data.target) {
        // If internal link
        return (
            <a // TODO: Add Gatsby <Link> back here - problem with #anchor links removing url params
                className={concatenatedClasses}
                href={link}
                style={style}
            >
                {parse(data.title)}
            </a>
        )
    } else {
        return (
            <a className={concatenatedClasses} href={link} target={data.target} style={style}>
                {parse(data.title)}
            </a>
        )
    }
}

ButtonLink.propTypes = {
    /**
     * Data
     */
    data: PropTypes.PropTypes.shape({
        url: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        target: PropTypes.string
    }).isRequired,
    /**
     * Specify a different style variant
     */
    variant: PropTypes.oneOf(['primary', 'secondary', 'inverse', 'feature', 'alt1', 'alt2', 'alt3', 'alt4', 'alt5']),
    /**
     * Optional set alternate button size
     */
    size: PropTypes.oneOf(['sm', 'lg']),
    /**
     * Optional set button icon
     */
    icon: PropTypes.oneOf(['arrow', 'chevron', 'external']),
    /**
     * Optional CSS classes
     */
    className: PropTypes.string,
    /**
     * Optional additional styles (USE SPARINGLY)
     */
    style: PropTypes.object,
    /**
     * Optional page UTMs - e.g. `?utm_campaign=campaign_name&utm_source=source_name`
     */
    utm: PropTypes.string
}

ButtonLink.defaultProps = {
    data: {
        url: '#',
        title: 'Button Title'
    },
    utm: ''
}

export default ButtonLink
